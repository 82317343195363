import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import { Link } from 'gatsby'
import ScrollButton from "../components/scroll-button/scroll-button";
import DynamicBlocks from 'components/dynamic-blocks/dynamic-blocks'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import './templates.sass'

const Template = ({ data }) => {
  const d = data.allCareersYaml.edges[0].node;

  return (
    <Layout bodyClass="k-reverse-header Career">
      <SEO
        title={`${d.title} | Careers`}
        metaTitle={d.meta.title}
        keywords={d.meta.keywords}
        description={d.meta.description} />
      <section className="k-bg--grey Career__header">
        <div className="container-fluid container-fluid--small k-margin-0-auto">
          <Link className="Career__backLink text-decoration-none mb-2" to="/careers">
            <span className="mr-1">&larr;</span>
            <span>Back to careers</span>
          </Link>
          <h1 className="h2--new mb-3">{d.title}</h1>
          <p className="mb-4">{d.summary}</p>
          <ScrollButton
            to="#k-anchor__get-in-touch"
            className="Career__btn btn">
            Apply for this role
          </ScrollButton>
        </div>
      </section>
      <section className="container-fluid container-fluid--small k-section pb-0">
        <ul className="Career__summary">
          {d.experience ? <li><b>Experience level:</b> {d.experience}</li> : null}
          {d.technology ? <li><b>Tech stack used:</b> {d.technology}</li> : null}
          {d.primarySkills ? <li><b>Primary skills we consider:</b> {d.primarySkills}</li> : null}
          {d.secondarySkills ? <li><b>Secondary skills we consider:</b> {d.secondarySkills}</li> : null}
          {d.compensation ? <li><b>Compensation:</b> {d.compensation}</li> : null}
          {d.employmentType ? <li><b>Employment type:</b> {d.employmentType}</li> : null}
          {d.remoteWorking ? <li><b>Remote working:</b> {d.remoteWorking}</li> : null}
          {d.visa ? <li><b>Visa sponsorship:</b> {d.visa}</li> : null}
        </ul>
      </section>
      <DynamicBlocks data={d.sections}/>
      <GetInTouch/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    allCareersYaml(
      filter: {
        path: { eq: $path }
      }
    ) {
      edges {
        node {
          path
          meta {
            title
            keywords
            description
          }
          title
          summary
          location
          department
          experience
          technology
          primarySkills
          secondarySkills
          compensation
          employmentType
          remoteWorking
          visa
          sections {
            classes
            component
            content
          }
        }
      }
    }
  }
`

export default Template
